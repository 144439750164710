<template>
    <section class="reviews">
        <h2 class="section-title title">
            {{ $t("reviews.title") }}
        </h2>
        <div class="container">
            <div class="container__video-preview video-preview" v-for="(video, index) in videos" :key="index">
                <div class="video-preview__image-container">
                    <img
                        @click="openModal(video.url)"
                        class="video-preview__image"
                        :src="getPreviewImage(index)"
                        alt="Review preview"
                    />
                </div>

                <p class="video-preview__author">{{ video.author }}</p>
            </div>
        </div>

        <div class="load-more" v-if="isShowLoadMoreButton">
            <button class="load-more__btn" @click="loadMore">
                {{ $t("reviews.loadMore") }}
            </button>
        </div>

        <UiModal :visible="isModalVisible" @closeModal="closeModal">
            <div class="modal__container">
                <iframe
                    :src="modalVideoUrl"
                    cellspacing="0"
                    style="border-style: none; width: 100%; height: 100%"
                ></iframe>
            </div>
        </UiModal>
    </section>
</template>

<script>
import Button from "../../components/UI/Button.vue"

export default {
    components: { Button },
    name: "LReviewsSection",
    data() {
        return {
            isModalVisible: false,
            modalVideoUrl: null,
            isShowLoadMoreButton: true,
            videos: [
                {
                    url: "https://www.youtube.com/embed/lky30gCn3vY",
                    author: "Lindy Shirries"
                },
                {
                    url: "https://www.youtube.com/embed/hEnBGa88rqY",
                    author: "Isaac Joshua Chiroma"
                },
                {
                    url: "https://www.youtube.com/embed/_tD1zLijM3I",
                    author: "Mulalo"
                },
                {
                    url: "https://www.youtube.com/embed/twciaIo4xEk",
                    author: "Faheem Yaseen"
                },
                {
                    url: "https://www.youtube.com/embed/dqSHWISZZhc",
                    author: "Johnmary"
                },
                {
                    url: "https://www.youtube.com/embed/s1qLOHmtzno",
                    author: "Ruth"
                }
            ],
            mock: [
                {
                    url: "https://www.youtube.com/embed/xdhg-9aUGiw",
                    author: "Sandro Flora"
                },
                {
                    url: "https://www.youtube.com/embed/C2OWXNpXNUo",
                    author: "Yanto"
                },
                {
                    url: "https://www.youtube.com/embed/d8Z50m7BL1k",
                    author: "Saleem"
                },
                {
                    url: "https://www.youtube.com/embed/cJz-PafcyjE",
                    author: "Bio Soiong"
                },
                {
                    url: "https://www.youtube.com/embed/vKDn8PCVI9k",
                    author: "Akhmat Adam"
                },
                {
                    url: "https://www.youtube.com/embed/sJm0kTUfeZo",
                    author: "Irfan Rohyana"
                },
                {
                    url: "https://www.youtube.com/embed/VBUXWW2Llw8",
                    author: "Alexey"
                },
                {
                    url: "https://www.youtube.com/embed/x_ij5U-p7sI",
                    author: "Chukwuma Beatrice"
                },
                {
                    url: "https://www.youtube.com/embed/30_aJ3_0Ugs",
                    author: "Hayat Muhammad Hamdard"
                },
                {
                    url: "https://www.youtube.com/embed/miBu-ogC-f0",
                    author: "Adel"
                },
                {
                    url: "https://www.youtube.com/embed/4vwSfGsWVcQ",
                    author: "Saint Emma"
                },
                {
                    url: "https://www.youtube.com/embed/-9Dw7vDufuo",
                    author: "Shyam Aharwar"
                },
                {
                    url: "https://www.youtube.com/embed/-mz7BEEHXes",
                    author: "Nelly"
                },
                {
                    url: "https://www.youtube.com/embed/FywgPS5B2pI",
                    author: "Shihan Sumon"
                },
                {
                    url: "https://www.youtube.com/embed/uRfk6XUsp1I",
                    author: "Kurra Malleswari"
                },
                {
                    url: "https://www.youtube.com/embed/tpsRUNofUWM",
                    author: "Mirza Akhlaque Baig"
                },
                {
                    url: "https://www.youtube.com/embed/PMSIsj3z-RY",
                    author: "Amina"
                },
                {
                    url: "https://www.youtube.com/embed/Zy3ZkIB8LQk",
                    author: "Bikash Kumar Das"
                },
                {
                    url: "https://www.youtube.com/embed/bsfo9kExcn4",
                    author: "Ranto Marbun"
                },
                {
                    url: "https://www.youtube.com/embed/OUTQ5B3Upw0",
                    author: "Shedrack Chukwuemeka"
                },
                {
                    url: "https://www.youtube.com/embed/udCGuIThLKE",
                    author: "Kholismulya"
                },
                {
                    url: "https://www.youtube.com/embed/shMi3uEriKk",
                    author: "Mmadu Akpan"
                },
                {
                    url: "https://www.youtube.com/embed/tashZvobPtc",
                    author: "Olga Sergiivna"
                },
                {
                    url: "https://www.youtube.com/embed/9G7iLmYev6M",
                    author: "Dewi Jusiana"
                },
                {
                    url: "https://www.youtube.com/embed/_R2fnJcbO7M",
                    author: "Kenzy"
                },
                {
                    url: "https://www.youtube.com/embed/AFYurgdzFNc",
                    author: "Denejniy Doctor"
                },
                {
                    url: "https://www.youtube.com/embed/1TBRVc2TySQ",
                    author: "Maryana Shmilenko"
                },
                {
                    url: "https://www.youtube.com/embed/KmnGkmBxWC8",
                    author: "Eldjo"
                },
                {
                    url: "https://www.youtube.com/embed/yy06W48Qx1w",
                    author: "Golden"
                },
                {
                    url: "https://www.youtube.com/embed/9b8eQx367HE",
                    author: "Malik Zain"
                },
                {
                    url: "https://www.youtube.com/embed/o7Ii1dFXJgU",
                    author: "Valeria"
                },
                {
                    url: "https://www.youtube.com/embed/skZjXYvX5eE",
                    author: "Haris"
                },
                {
                    url: "https://www.youtube.com/embed/OG2ldloVW1Y",
                    author: "Ivan Levan"
                },
                {
                    url: "https://www.youtube.com/embed/7YRHFqn19Es",
                    author: "Liu Chao"
                },
                {
                    url: "https://www.youtube.com/embed/Eo1WLA44gfI",
                    author: "Prina Prahatin"
                },
                {
                    url: "https://www.youtube.com/embed/iucIU5vQPCw",
                    author: "Michi"
                },
                {
                    url: "https://www.youtube.com/embed/Fv1jlR5q4jY",
                    author: "Stanislav"
                },
                {
                    url: "https://www.youtube.com/embed/4qYOHKRlXN8",
                    author: "Wang Dan"
                },
                {
                    url: "https://www.youtube.com/embed/oQ9i-QV6DXQ",
                    author: "Svetlana"
                },
                {
                    url: "https://www.youtube.com/embed/pttv6RYxuEQ",
                    author: "Agus Kusnadi"
                }
            ]
        }
    },
    methods: {
        openModal(videoUrl) {
            this.modalVideoUrl = videoUrl
            this.isModalVisible = true
        },
        closeModal() {
            this.modalVideoUrl = null
            this.isModalVisible = false
        },
        loadMore() {
            const itemsCount = this.videos.length - 5
            this.videos = this.videos.concat(this.mock.slice(itemsCount - 1, itemsCount + 5))

            if (this.videos.length === this.mock.length + 6) this.isShowLoadMoreButton = false
        },
        getPreviewImage(index) {
            // return new URL(`/src/assets/images/reviews/reviews_${index + 1}.png`)
            return require(`@/assets/images/reviews/reviews_${index + 1}.png`)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/mixins";

.title {
    text-align: center;
    margin-bottom: 50px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    margin-bottom: 100px;
    @media screen and (max-width: 1250px) {
        grid-template-columns: 1fr 1fr;
    }
    @include mobile-s {
        grid-template-columns: 1fr;
    }
}

.video-preview {
    &__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        margin-bottom: 20px;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        transition: 0.3s transform;

        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.load-more {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    &__btn {
        background: transparent;
        border: 1px solid #ffffff;
        border-radius: 10px;
        transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;

        line-height: 23px;
        font-weight: 500;
        padding: 15px 25px;
        color: inherit;

        &:hover {
            border-color: #ef9e40;
            color: #ef9e40;
        }
    }
}

::v-deep .modal__inner {
    max-width: 1200px;
    height: 600px;
}
</style>
